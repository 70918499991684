import React from 'react';
import { fetchAPI } from '../../lib/api';
import Layout from '../../components/layout/layout';
import settings from '../../settings.json';
import { default as Components, ComponentMappings } from '../../components/blocks/components';
import { uid } from 'react-uid';
import { H1, Row } from '../../components/styleguide-remapped/styleguide-remapped';
import Chip from '../../components/custom/chip';
import { PrimarySearchBarWrapper, PrimaryLayoutWrapper } from '../../assets/css/style';
import { purgeNextjs404Files } from "../../lib/purgeNextjs404Files";

const Category = ({ category, categories, relatedTopics, is404 }) => {
  const pageType = 'CATEGORY';
  const relatedCategories = categories.filter((d) => d.attributes.slug !== category.attributes.slug);
  console.log('category:', category, categories, relatedTopics);

  return (
    <Layout seo={category.attributes.seo} key={uid(category)} pageType={pageType} documentData={category} is404={is404}>
      <PrimarySearchBarWrapper>
        <Row className="breadcrumb-row">
          <Chip title="ARD Hilfe" link="/" />
        </Row>
        <H1> {category.attributes.title} </H1>
        {React.createElement(ComponentMappings['block.searchbar'], {
          key: `comp-${uid(category.attributes.searchbar)}`,
          block: {
            ...{
              __component: 'block.searchbar',
              id: 'block.searchbar-' + uid(category.attributes.searchbar.id),
              mappingIndex: `comp-${uid(category.attributes.searchbar.id)}`,
            },
            ...category.attributes.searchbar,
          },
          documentData: category,
          pageType: pageType,
          relatedCategories: relatedCategories ?? [],
          relatedTopics: relatedTopics ?? [],
        })}
      </PrimarySearchBarWrapper>
      <PrimaryLayoutWrapper>
        {category.attributes.popularArticles &&
          React.createElement(ComponentMappings['block.related-articles-2-columns'], {
            key: `comp-${uid(category.attributes.popularArticles)}`,
            block: {
              ...{
                __component: 'block.related-articles-2-columns',
                id: 'block.related-articles-2-columns-' + uid(category.attributes.popularArticles.id),
                mappingIndex: `comp-${uid(category.attributes.popularArticles.id)}`,
              },
              ...category.attributes.popularArticles,
            },
            documentData: category,
            pageType: pageType,
            relatedCategories: relatedCategories ?? [],
            relatedTopics: relatedTopics ?? [],
          })}
        {React.createElement(ComponentMappings['block.related-topics'], {
          key: `comp-${uid(category.attributes.relatedTopics)}`,
          block: {
            ...{
              __component: 'block.related-topics',
              id: 'block.related-topics-' + uid(category.attributes.relatedTopics.id),
              mappingIndex: `comp-${uid(category.attributes.relatedTopics.id)}`,
            },
            ...category.attributes.relatedTopics,
          },
          documentData: category,
          pageType: pageType,
          relatedCategories: relatedCategories ?? [],
          relatedTopics: relatedTopics ?? [],
        })}
        {React.createElement(ComponentMappings['block.contact-cta'], {
          key: `comp-${uid(category.attributes.contactCta)}`,
          block: {
            ...{
              __component: 'block.contact-cta',
              id: 'block.contact-cta-' + uid(category.attributes.contactCta.id),
              mappingIndex: `comp-${uid(category.attributes.contactCta.id)}`,
            },
            ...category.attributes.contactCta,
          },
          documentData: category,
          pageType: pageType,
          relatedCategories: relatedCategories ?? [],
          relatedTopics: relatedTopics ?? [],
        })}
        {React.createElement(ComponentMappings['block.related-categories'], {
          key: `comp-${uid(category.attributes.relatedCategories)}`,
          block: {
            ...{
              __component: 'block.related-categories',
              id: 'block.related-categories-' + uid(category.attributes.relatedCategories.id),
              mappingIndex: `comp-${uid(category.attributes.relatedCategories.id)}`,
            },
            ...category.attributes.relatedCategories,
          },
          documentData: category,
          pageType: pageType,
          relatedCategories: relatedCategories ?? [],
          relatedTopics: relatedTopics ?? [],
        })}
        {category.attributes.blockContent && category.attributes.blockContent.length ? (
          category.attributes.blockContent.map((block, index) => {
            block.mappingIndex = index;

            if (settings.skipableElements.includes(block.__component)) {
              return false;
            }

            return <>{Components(block, category, pageType, relatedCategories, relatedTopics)}</>;
          })
        ) : (
          <></>
        )}
      </PrimaryLayoutWrapper>
    </Layout>
  );
};

export async function getStaticPaths() {
  let is404 = false;
  const categoriesRes = await fetchAPI('/categories', { fields: ['slug'] }).catch(() => {
    is404 = true;
    return null;
  });

  const paths = is404
    ? []
    : categoriesRes?.data?.map((category) => ({
        params: {
          category: category.attributes.slug,
        },
      })) ?? [];

  return {
    paths: paths,
    fallback: settings.useFallbackPaths ?? false,
  };
}

export async function getStaticProps({ params, preview }) {
  const [matchingCategories, allCategories, relatedTopics] = await Promise.all([
    fetchAPI('/categories', {
      filters: { slug: params.category },
      populate: 'deep',
      sort: { rank: 'asc' },
      publicationState: preview ? 'preview' : 'live',
    }),
    fetchAPI('/categories', {
      populate: '*',
      sort: { rank: 'asc' },
      publicationState: preview ? 'preview' : 'live',
    }),
    fetchAPI('/topics', {
      category: {
        slug: {
          $contains: params.category,
        },
      },
      publicationState: preview ? 'preview' : 'live',
      pagination: {
        pageSize: 100,
      },
      populate: 'deep',
      sort: { rank: 'asc' },
    }),
  ]);

  const is404 = matchingCategories?.data[0] ? false : true;

  if (is404) {
    await purgeNextjs404Files([params.category]);
  }

  return {
    props: {
      category: matchingCategories?.data[0] ?? {},
      categories: allCategories?.data ?? [],
      relatedTopics: relatedTopics?.data ?? [],
      is404: is404
    },
    revalidate: is404 && !settings.prodMode ? undefined : settings.cacheRefreshSeconds,
    notFound: is404,
  };
}

export default Category;
